import React, { useContext, useEffect, useState } from "react";
import BeWithUs from "../components/BeWithUs";
import CardWithBgList from "../components/cards/CardWithBgList";
import Pagination from "../components/Pagination";
import NewsCardList from "../components/cards/NewsCardList";
import PdfDownloadContainer from "../components/cards/PdfDownloadContainer";
import setting from "../setting.json";
import axios from "axios";
import { LanguageContext } from "../components/utils/lanContext";
import { useParams } from "react-router-dom";

const ArkadaglyYaslar = () => {
  const { language } = useContext(LanguageContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [realTotalPage, setRealTotalPage] = useState();
  const [popular, setPopular] = useState();
  const [mainDataList, setMainDataList] = useState();
  const { id } = useParams();
  async function MainList() {

    axios
      .get(setting.url + "/gender/gender/?type="+id, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        // Handle successful response
        setMainDataList(response.data);
        if (response?.data.count / 4 > parseInt(response?.data.count / 4)) {
          setRealTotalPage(parseInt(response?.data.count / 4) + 1);
        } else {
          setRealTotalPage(parseInt(response?.data.count / 4));
        }
      })
      .catch(function (error) {
        // Handle error
        console.error("Error:", error);
      });
  }
  async function PopularNews() {
    axios
      .get(`${setting.url}/bolum/home/`, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        // Handle successful response
        setPopular(response.data);
      })
      .catch(function (error) {
        // Handle error
        console.error("Error:", error);
      });
  }
  useEffect(() => {
    PopularNews();
    MainList();
    setCurrentPage(1);
  }, [currentPage, language,id]);
  return (
    <div>
      
        <div className="flex m-auto w-11/12 lg:w-9/12 max-w-[1400px] lg:flex-row flex-col gap-3">
          <div className="lg:w-[65%] w-full">
            <PdfDownloadContainer ListCards={mainDataList} />
            <Pagination
              ListCards={mainDataList}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              realTotalPage={realTotalPage}
              setRealTotalPage={setRealTotalPage}
              part={4}
            />
          </div>
          <div className="lg:w-[35%] w-full">
            <CardWithBgList ListCards={popular} title={"Meňzeş Habarlar"} />
          </div>
        </div>
        <BeWithUs />
      
    </div>
  );
};

export default ArkadaglyYaslar;
