import React, { useState, useEffect } from "react";

const Pagination = ({
  ListCards,
  currentPage,
  setCurrentPage,
  realTotalPage,
  setRealTotalPage,
  part,
}) => {
  const totalPage = ListCards?.count / part;
  useEffect(() => {}, []);

  return (
    <>
      {realTotalPage > 1 && (
        <div className="flex gap-2 m-auto w-max select-none dark:text-white">
          {currentPage > 1 && (
            <div
              onClick={() => setCurrentPage(currentPage - 1)}
              className="border-[1px] px-2 rounded-md text-center cursor-pointer"
            >{`<`}</div>
          )}
          {currentPage > 1 && (
            <div
              onClick={() => setCurrentPage(1)}
              className="border-[1px] px-2 rounded-md text-center cursor-pointer"
            >
              1
            </div>
          )}
          {currentPage > 2 && <div className="">...</div>}
          <div className="border-[1px] px-2 rounded-md text-center cursor-pointer border-blue-500 text-blue-500">
            {currentPage}
          </div>
          {totalPage - currentPage > 1 && <div className="">...</div>}
          {currentPage < realTotalPage - 1 && (
            <div
              onClick={() => setCurrentPage(realTotalPage - 1)}
              className="border-[1px] px-2 rounded-md text-center cursor-pointer"
            >
              {realTotalPage - 1}
            </div>
          )}
          {currentPage < realTotalPage && (
            <div
              onClick={() => setCurrentPage(realTotalPage)}
              className="border-[1px] px-2 rounded-md text-center cursor-pointer"
            >
              {realTotalPage}
            </div>
          )}
          {ListCards?.next && currentPage < realTotalPage && (
            <div
              onClick={() => setCurrentPage(currentPage + 1)}
              className="border-[1px] px-2 rounded-md text-center cursor-pointer"
            >{`>`}</div>
          )}
        </div>
      )}
    </>
  );
};
export default Pagination;
