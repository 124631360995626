import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import Home from "./pages/Home";
import Navbar from "./components/container/Navbar";
import Footer from "./components/container/Footer";
import NewsOpen from "./pages/NewsOpen";
import News from "./pages/News";
import { LanguageContext } from "./components/utils/lanContext";
import { Suspense, memo, useState } from "react";
import Gallery from "./pages/Gallery";
import GalleryOpen from "./pages/GalleryOpen";
import VidoePage from "./pages/VidoePage";
import ArkadaglyYaslar from "./pages/ArkadaglyYaslar";
import Spinner from "./components/spinner/Spinner";
import { Theme } from "@radix-ui/themes";
import "@radix-ui/themes/styles.css";
import MyPdfViewer from "./components/pdf/PdfReaer";
import Pdf from "./pages/Pdf";
import News2 from "./pages/News2";

const MemoizedComponent = memo(Navbar);
function App() {
  const [language, setLanguage] = useState(
    localStorage.getItem("i18nextLng") || "tm"
  );
  const [kabulhanaToggle, setKabulhanaToggle] = useState();
  const createToggler = (tg) => {
    setKabulhanaToggle(tg);
  };
  const createLanguage = (lan) => {
    setLanguage(lan);
  };
  return (
    <LanguageContext.Provider
      value={{
        language: language,
        kabulhanaToggle: kabulhanaToggle,
        createToggler: createToggler,
        createLanguage: createLanguage,
        setKabulhanaToggle: (value) => {
          setKabulhanaToggle(value);
        },
        setLanguage: (value) => {
          setLanguage(value);
        },
      }}
    >
      <Theme>
      <div className="dark:bg-[#202020]">
        {/* <ModalKabul /> */}
          <Suspense fallback={<Spinner />}>
        <Navbar />
        <div className="mt-[120px] lg:mt-[80px] xl:mt-[100px]  m-auto  ">

          <Routes>

            <Route path="/pdfreader" element={<Pdf />} />
            <Route path="/" element={<Home />} />
            <Route path="/contact-us" element={<AboutUs />} />
            {/* news */}
            <Route path="/news" element={<News />} />
            <Route path="/yunesko" element={<News />} />
            <Route path="/bilim" element={<News />} />
            <Route path="/sport" element={<News />} />
            <Route path="/makalalar" element={<News />} />
            <Route path="/medeni-miras" element={<News />} />
            <Route path="/basleshikler" element={<News />} />
            <Route path="/isjen-agzalar" element={<News />} />
            <Route path="/yaslar-syyasaty" element={<News />} />
            <Route path="/kanunlar-we-kararlar" element={<News />} />
            <Route path="/okuwlar" element={<News />} />
            <Route path="/gender-denligi/:id" element={<ArkadaglyYaslar />} />
            <Route path="/careler" element={<News />} />
            <Route path="/kalendar" element={<News2 />} />
            {/* zenanlar */}
            <Route path="/zenanlar/:id" element={<News />} />
            <Route path="/welayatlar" element={<News />} />
            <Route path="/halkara-gatnashyklar" element={<News />} />
            {/* kesdecilik halycylyk kececilik sungaty */}
            <Route path="/kesdecilik-sungaty" element={<News />} />
            <Route path="/halycylyk-sungaty" element={<News />} />
            <Route path="/kececilik-sungaty" element={<News />} />

            <Route path="/kesdecilik-sungaty/:id" element={<NewsOpen />} />
            <Route path="/halycylyk-sungaty/:id" element={<NewsOpen />} />
            <Route path="/kececilik-sungaty/:id" element={<NewsOpen />} />

            {/* news open */}
            <Route path="/news/:id" element={<NewsOpen />} />
            <Route path="/yunesko/:id" element={<NewsOpen />} />
            <Route path="/bilim/:id" element={<NewsOpen />} />
            <Route path="/sport/:id" element={<NewsOpen />} />
            <Route path="/makalalar/:id" element={<NewsOpen />} />
            <Route path="/basleshikler/:id" element={<NewsOpen />} />
            
            {/* ------------------ */}
            <Route path="/isewir-zenanlar-merkezi/:id" element={<NewsOpen />} />
            <Route path="/alym-zenanlar-merkezi/:id" element={<NewsOpen />} />
            <Route
              path="/zenanlaryn-kesdecilik-sungaty-merkezi/:id"
              element={<NewsOpen />}
            />
            {/* --------------------- */}
            <Route path="/careler/:id" element={<NewsOpen />} />
            <Route path="/kanunlar-we-kararlar/:id" element={<NewsOpen />} />
            <Route path="/okuwlar/:id" element={<NewsOpen />} />
            <Route path="/ogulabat-eje/:id" element={<NewsOpen />} />

            {/* <Route path="/gender-denligi/:id" element={<NewsOpen />} /> */}
            {/* zenanlar:id */}
            <Route path="/zenanlar/open/:id" element={<NewsOpen />} />
            <Route path="/welayatlar/open/:id" element={<NewsOpen />} />
            <Route path="/halkara-gatnashyklar/:id" element={<NewsOpen />} />
            {/* gallery */}
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/gallery/:id" element={<GalleryOpen />} />
            {/* video page */}
            <Route path="/videos" element={<VidoePage />} />
          </Routes>
        </div>
        <Footer />
          </Suspense>
      </div>
      </Theme>

    </LanguageContext.Provider>
  );
}

export default function WrappedApp() {
  return <App />;
}
