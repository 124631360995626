import React from "react";
import formatDate from "../utils/formatDate";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CardWithBgList = ({ ListCards = [], title }) => {
  const { t } = useTranslation();

  return (
    <div className="">
      <h1 className="text-[#404040] dark:text-white text-2xl font-bold font-['Mulish']">
        {t("main." + title)}
      </h1>
      <div className="p-5 pl-0 flex flex-col gap-4">
        {ListCards &&
          ListCards?.map((item, i) => (
            <Link to={"/news/" + item.id} key={i} className="flex gap-4">
              <div className="relative flex-2">
                <img
                  alt=""
                  src={item?.thumbnail}
                  className="w-[148.71px] h-[91.05px] object-cover"
                />
              </div>
              <div className="flex flex-col justify-between flex-1">
                <h1 className="text-black dark:text-[#C7C3DD] text-lg font-normal  ">
                  {item?.title}
                </h1>
                <div className="text-zinc-500 text-xs font-normal font-['Mulish'] tracking-wide ">
                  {formatDate(
                    item?.created_at || "2023-11-29T11:53:17.232769+05:00"
                  )}
                </div>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
};
export default CardWithBgList;
