import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const NewsCardList = ({ ListCards, location, id }) => {
  const [formattedDate, setFormattedDate] = useState("");
  function handleDate() {
    const originalDateString = "2023-11-26T15:41:52.879458+05:00";
    const originalDate = new Date(originalDateString);

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: "UTC", // Adjust the timeZone according to your needs
    };

    const formattedDateString = new Intl.DateTimeFormat(
      "en-US",
      options
    ).format(originalDate);

    setFormattedDate(formattedDateString);
  }
  useEffect(() => {
    handleDate();
  }, []);
  return (
    <div className="">
      <div className="p-5 flex flex-col gap-4">
        {ListCards?.results?.map((item, i) => {
          const formatDate = (originalDateString) => {
            const originalDate = new Date(originalDateString);
            const options = {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
              timeZone: "UTC", // Adjust the timeZone according to your needs
            };

            return new Intl.DateTimeFormat("en-US", options).format(
              originalDate
            );
          };
          let locas;
          if (location === "/news") {
            locas = `/news/` + item.id;
          }
          if (location === "/yunesko") {
            locas = `/yunesko/` + item.id;
          }
          if (location === "/bilim") {
            locas = `/bilim/` + item.id;
          }
          if (location === "/sport") {
            locas = `/sport/` + item.id;
          }
          if (location === "/makalalar") {
            locas = `/makalalar/` + item.id;
          }
          if (location === "/medeni-miras") {
            locas = `/medeni-miras/` + item.id;
          }
          if (location === "/basleshikler") {
            locas = `/basleshikler/` + item.id;
          }
          if (location === "/isjen-agzalar") {
            locas = `/isjen-agzalar/` + item.id;
          }
          if (location === "/yaslar-syyasaty") {
            locas = `/yaslar-syyasaty/` + item.id;
          }
          if (location === "/kanunlar-we-kararlar") {
            locas = `/kanunlar-we-kararlar/` + item.id;
          }

          if (location === "/halkara-gatnashyklar") {
            locas = `/halkara-gatnashyklar/` + item.id;
          }
          if (location === "/careler") {
            locas = `/careler/` + item.id;
          }
          if (location === "/zenanlar" + id) {
            locas = `/zenanlar/open/` + item.id;
          }
          if (location === "/welayatlar/open" + id) {
            locas = `/welayatlar/open/` + item.id;
          }if (location === "/kesdecilik-sungaty") {
            locas = `/kesdecilik-sungaty/` + item.id;
          }
          if (location === "/halycylyk-sungaty") {
            locas = `/halycylyk-sungaty/` + item.id;
          }
          if (location === "/kececilik-sungaty") {
            locas = `/kececilik-sungaty/` + item.id;
          }
          return (
            <Link key={i} to={locas} className="sm:flex  gap-4">
              <img
                alt=""
                src={item.thumbnail}
                className="sm:min-w-[204px]  min-w-full h-[214px] object-cover rounded-lg "
              />
              <div className="flex flex-col justify-between">
                <h1 className="text-black dark:text-white text-md  sm:text-xl text-justify  font-normal">
                  {item.title}
                </h1>
                <div className="text-zinc-500 text-sm font-medium">
                  {formatDate(item.created_at)}
                  {/* {item.created_at} */}
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
export default NewsCardList;
