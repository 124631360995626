// Home.js
import React, { useContext, useEffect, useState } from "react";
import NewsCardList from "../components/cards/NewsCardList";
import CardWithBgList from "../components/cards/CardWithBgList";
import BeWithUs from "../components/BeWithUs";
import axios from "axios";
import setting from "../setting.json";
import { Link, useLocation } from "react-router-dom";
import Pagination from "../components/Pagination";
import axiosInstance from "../components/utils/axiosInstance";
import { LanguageContext } from "../components/utils/lanContext";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NewsCardList2 from "../components/cards/NewsCardList2";
const News2 = () => {
  const searchParams = new URLSearchParams(
    window.location.href.substring(window.location.href.indexOf("?") + 1)
  );
  const date = searchParams.get("date");

  const { language } = useContext(LanguageContext);
  const { id } = useParams();
  const location = useLocation().pathname.toLowerCase();
  const [newest, setNewset] = useState([]);
 
  const [popular, setPopular] = useState();

  async function PopularNews() {
    axios
      .get(`${setting.url}/bolum/home/`, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        setPopular(response.data);
      })
      .catch(function (error) {
        console.error("Error:", error);
      });
  }
  async function Calendar() {
    axios
      .get(setting.url + "/bolum/boulm-tazelik-filter?created_at=" + date, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        setNewset((prevNewest) => [...prevNewest, ...response.data]);
      })
      axios
      .get(setting.url + "/home/chare-filter?created_at=" + date, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        setNewset((prevNewest) => [...prevNewest, ...response.data]);

       
      })
      axios
      .get(setting.url + "/home/millilik-filter?created_at=" + date, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        setNewset((prevNewest) => [...prevNewest, ...response.data]);

       
      })
      axios
      .get(setting.url + "/home/okuw-filter?created_at=" + date, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        setNewset((prevNewest) => [...prevNewest, ...response.data]);

       
      })
      axios
      .get(setting.url + "/news/welayat-news-filter?created_at=" + date, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        setNewset((prevNewest) => [...prevNewest, ...response.data]);

       
      })
      axios
      .get(setting.url + "/yunesko/yunesko-filter?created_at=" + date, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        setNewset((prevNewest) => [...prevNewest, ...response.data]);

       
      })
      axios
      .get(setting.url + "/home/scientist-filter?created_at=" + date, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        setNewset((prevNewest) => [...prevNewest, ...response.data]);

       
      })
      axios
      .get(setting.url + "/news/news-filter?created_at=" + date, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        setNewset((prevNewest) => [...prevNewest, ...response.data]);

       
      })
  }
  
  useEffect(() => {
    if (location == "/kalendar") {
      Calendar();
    }
    PopularNews();
  }, [location,  language, date]);
  const { t } = useTranslation();
  console.log(newest)
  return (
    <div className="">
      <div className="flex  lg:flex-row flex-col gap-3 m-auto w-11/12 lg:w-9/12 max-w-[1400px]">
        <div className="xl:w-[65%] w-full">
          <NewsCardList2 location={location} ListCards={newest} id={id} />
         
        </div>
        <div className="xl:w-[35%]  w-11/12 lg:w-9/12 max-w-[1400px]">
          {location !== "/welayatlar" && (
            <CardWithBgList ListCards={popular} title={"Meňzeş Habarlar"} />
          )}
        
        </div>
      </div>
      <BeWithUs />
    </div>
  );
};

export default News2;
