import React from "react";
import formatDate from "../utils/formatDate";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ListCardCategory = ({ ListCards, title, link, linkA }) => {
  const { t } = useTranslation();

  return (
    <div className="border-[1px] border-[#22683E] dark:border-neutral-800 rounded-md bg-white dark:bg-black ">
      <h1 className="bg-[#22683E] text-[20px] font-medium border-b-[1px] border-black/20 dark:border-neutral-800 p-5 text-white flex justify-between items-center rounded-md">
        {t("main." + title || ListCards?.title)}
        {linkA && (
          <Link
            to={linkA}
            className="text-white text-[22px] md:text-xl text-center"
          >
            {t("main.Ahlisi")}
          </Link>
        )}
      </h1>
      <div className="p-5 flex flex-col gap-4">
        {ListCards?.results?.map((item, i) => (
          <Link to={link + item.id} key={i} className="flex gap-4">
            <img
              alt=""
              src={item?.thumbnail}
              className="w-[136px] h-[90px] rounded-lg object-cover"
            />
            <div>
              <h1 className="text-black dark:text-white text-sm font-normal">
                {item?.title}
              </h1>
              <div className="text-zinc-500 text-sm font-medium">
                {/* {item.created_at} */}
                {formatDate(item?.created_at)}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
export default ListCardCategory;
