import React, { useContext, useEffect, useState } from "react";
import { useFetcher, useParams } from "react-router-dom";
import axios from "axios";
import setting from "../setting.json";
import { LanguageContext } from "../components/utils/lanContext";
import CardWithBgList from "../components/cards/CardWithBgList";

const GalleryOpen = () => {
  const { id } = useParams();
  const { language } = useContext(LanguageContext);
  const [photos, setPhotos] = useState();
  const [currentImage, setCurrentImage] = useState("");
  const [imageTogler, setImageTogler] = useState(false);
  async function getPhotos() {
    axios
      .get(`${setting.url}/multimedia/image/?thumbnail=` + id, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        // Handle successful response
        setPhotos(response.data);
      })
      .catch(function (error) {
        // Handle error
        console.error("Error:", error);
      });
  }
  const [popular, setPopular] = useState();

  async function PopularNews() {
    axios
      .get(`${setting.url}/bolum/home/`, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        // Handle successful response
        setPopular(response.data);
      })
      .catch(function (error) {
        // Handle error
        console.error("Error:", error);
      });
  }
  useEffect(() => {
    PopularNews();
    getPhotos();
  }, [language]);
  return (
    <div className="flex  lg:flex-row flex-col gap-10 m-auto w-11/12 lg:w-9/12 max-w-[1400px]">
      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-10 w-full">
        {photos?.map((item, i) => (
          <div
            key={i}
            onClick={(e) => {
              setCurrentImage(item.image);
              setImageTogler(true);
            }}
          >
            <img
              className="w-full object-cover h-72 "
              src={item.image}
              alt={item.thumbnail.name + "/" + item.id}
            />
          </div>
        ))}
        {imageTogler && (
          <div
            onClick={(e) => setImageTogler(false)}
            className="fixed flex justify-center bg-black/30 z-50 w-full h-full top-0 left-0 items-center"
          >
            <img className="h-[70%]" src={currentImage} alt="" />
          </div>
        )}
      </div>
      <div className="lg:w-[35%] w-full">
        <CardWithBgList ListCards={popular} title={"Meňzeş Habarlar"} />
      </div>
    </div>
  );
};
export default GalleryOpen;
