import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import setting from "../setting.json";
import { LanguageContext } from "../components/utils/lanContext";
import Pagination from "../components/Pagination";
import ReactPlayer from "react-player";
import BeWithUs from "../components/BeWithUs";
const VidoePage = () => {
  const { language } = useContext(LanguageContext);
  const [imageTogler, setImageTogler] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [realTotalPage, setRealTotalPage] = useState();
  const [videos, setVideos] = useState();
  const [currentVideo, setCurrentVideo] = useState(
    videos?.results[0]?.source || ""
  );
  function OpenTheHell(e){
    setImageTogler(true)
    setCurrentVideo(e)
  }
  async function getVideos() {
    axios
      .get(`${setting.url}/multimedia/video/`, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        // Handle successful response
        setVideos(response.data);
        setCurrentVideo(response.data.results[0].source);
        if (response?.data.count / 9 > parseInt(response?.data.count / 12)) {
          setRealTotalPage(parseInt(response?.data.count / 12) + 1);
        } else {
          setRealTotalPage(parseInt(response?.data.count / 12));
        }
      })
      .catch(function (error) {
        // Handle error
        console.error("Error:", error);
      });
  }
  useEffect(() => {
    getVideos();
  }, [language]);
  console.log(currentVideo)
  return (
    <div className=" ">
      
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-5 m-auto w-11/12 lg:w-9/12 max-w-[1400px]">
          {videos?.results?.map((item, i) => (
            <div
              onClick={(e) => OpenTheHell(item.source)}
              className="relative"
              key={i}
            >
              <img
                src={item.thumbnail}
                alt=""
                className="object-cover h-full"
              />
              <div className="absolute bg-black/30 w-full h-full top-0 left-0 text-white flex items-center justify-center">
                <img
                  src="/icons/bi_caret-right-fill.svg"
                  className="w-16"
                  alt=""
                />
              </div>
            </div>
          ))}
        </div>
        {imageTogler && (
          <div className="fixed flex justify-center  z-50 w-full h-full top-0 left-0 items-center">
            <div
              onClick={(e) => setImageTogler(false)}
              className="absolute w-full h-full top-0 left-0 bg-black/30"
            ></div>
            <div
              className="absolute z-10 border-2 bg-white p-1 px-2 top-10 right-10 rounded-md"
              onClick={(e) => setImageTogler(false)}
            >
              X
            </div>
            <ReactPlayer
              url={currentVideo}
              width="auto"
              height="70%"
              controls
            />
          </div>
        )}
        <Pagination
          ListCards={videos}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          realTotalPage={realTotalPage}
          setRealTotalPage={setRealTotalPage}
          part={12}
        />
     
      <BeWithUs />
    </div>
  );
};

export default VidoePage;
