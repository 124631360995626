import { createContext } from "react";

export const LanguageContext = createContext({
  // can set state values
  kabulhanaToggle: false,
  createToggler: () => {},
  setKabulhanaToggle: () => {},
  language: localStorage.getItem("i18nextLng") || "",
  setLanguage: () => {},
  createLanguage: () => {},
});
