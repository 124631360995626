import React from "react";
import formatDate from "../utils/formatDate";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

const ViewEyesCardList = ({ ListCards, title, linkA }) => {
  const { t } = useTranslation();
  return (
    <div className="  p-5 pb-0 pt-0 px-0  mt-0">
      <div className="m-auto w-11/12 lg:w-9/12 max-w-[1400px]">
        <h1 className="text-center text-neutral-900 dark:text-white md:text-[32px] font-bold   p-5 w-full flex items-center gap-5 whitespace-nowrap">
          {t("main." + title)}
          {/* <div className="border-t-2  border-dashed border-[#9D9D9D] w-full h-0 mt-1  "></div> */}
        </h1>
        <div className="p-5 ">
          <Swiper
            modules={[Autoplay]}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            loop
            breakpoints={{
              // when window width is >= 320px
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              // when window width is >= 480px
              480: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              // when window width is >= 640px
              800: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1000: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
            }}
            spaceBetween={30}
            className=""
          >
            {ListCards?.map((item, i) => (
              <SwiperSlide key={i} className="w-full">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.url}
                  className=" relative shadow-md rounded-lg"
                >
                  <img
                    alt=""
                    src={item.thumbnail}
                    className="h-[120px] object-contain  rounded-lg w-full"
                  />

                  <div className="">
                    <h1 className=" text-[13px] text-center font-bold px-5 pb-3 dark:text-white">
                      {item.name}
                    </h1>
                  </div>
                </a>
              </SwiperSlide>
            ))}{" "}
          </Swiper>
        </div>
      </div>
    </div>
  );
};
export default ViewEyesCardList;
