import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const NewsCardList2 = ({ ListCards, location, id }) => {
  const [formattedDate, setFormattedDate] = useState("");
  function handleDate() {
    const originalDateString = "2023-11-26T15:41:52.879458+05:00";
    const originalDate = new Date(originalDateString);

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: "UTC", // Adjust the timeZone according to your needs
    };

    const formattedDateString = new Intl.DateTimeFormat(
      "en-US",
      options
    ).format(originalDate);

    setFormattedDate(formattedDateString);
  }
  useEffect(() => {
    handleDate();
  }, []);
  return (
    <div className="">
      <div className="p-5 flex flex-col gap-4">
        {ListCards?.map((item, i) => {
          const formatDate = (originalDateString) => {
            const originalDate = new Date(originalDateString);
            const options = {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
              timeZone: "UTC", // Adjust the timeZone according to your needs
            };

            return new Intl.DateTimeFormat("en-US", options).format(
              originalDate
            );
          };
          let locas;
          if (item.slug === "news") {
            locas = `/news/` + item.id;
          }
          if (item.slug === "yunesko") {
            locas = `/yunesko/` + item.id;
          }
          if (item.slug === "bolum") {
            locas = `/bilim/` + item.id;
          }if (item.slug === "chare") {
            locas = `/careler/` + item.id;
          }
          if (item.slug === "millilik") {
            locas = `/kesdecilik-sungaty/` + item.id;
          }if (item.slug === "okuw") {
            locas = `/halkara-gatnashyklar/` + item.id;
          }
          if (item.slug === "welayat") {
            locas = `/welayatlar/` + item.id;
          }
         
          return (
            <Link key={i} to={locas} className="sm:flex  gap-4">
              <img
                alt=""
                src={item.thumbnail}
                className="sm:min-w-[204px]  w-[204px] h-[214px] object-cover rounded-lg "
              />
              <div className="flex flex-col justify-between">
                <h1 className="text-black dark:text-white text-md  sm:text-xl text-justify  font-normal">
                  {item.title}
                </h1>
                <div className="text-zinc-500 text-sm font-medium">
                  {formatDate(item?.created_at)}
                  {/* {item.created_at} */}
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
export default NewsCardList2;
