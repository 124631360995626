import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import axios from "axios";
import setting from "../../setting.json";
import { LanguageContext } from "../utils/lanContext";
import { useLocation } from "react-router-dom";
import tmIcon from "./tm.png";
import ruIcon from "./ru.png";
import enIcon from "./en.png";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import logo3 from "./Vector 6.png";
import logo4 from "./logo.png";
import formatDate from "../utils/formatDate";
import "./hamburger.css";
import logo from "./logo_anim_200x200.gif";
import DropdownMenuDemo from "../dropdawn";
// init Swiper:

function Navbar() {
  const listOfLink = [
    { title: "TZB-niň Bölümleri", items: [
      { title: "Aşgabat şäheri  bölümi", link: "welayatlar?w=0" },
      { title: "Arkadag şäheri bölumi", link: "welayatlar?w=1" },
      { title: "Ahal welaýat bölümi", link: "welayatlar?w=2" },
      { title: "Balkan welaýat bölümi", link: "welayatlar?w=3" },
      { title: "Daşoguz welaýat bölümi", link: "welayatlar?w=4" },
      { title: "Lebap welaýat bölümi", link: "welayatlar?w=5" },
      { title: "Mary welaýat bölümi", link: "welayatlar?w=6" },
    ] },
    { title: "Merkezler", items: [
      { title: "Işewir zenanlar", link: "/isewir-zenanlar-merkezi/111" },
      { title: "Alym zenanlar", link: "/alym-zenanlar-merkezi/4" },
      { title: "Zenanlaryň Keşdeçilik sungaty merkezi", link: "/zenanlaryn-kesdecilik-sungaty-merkezi/5" },
    ] },
    { title: "Täzelik", items: [
      { title: "Çareler", link: "/careler" },
      { title: "Täzelikler", link: "/News" },
      { title: "MAKALALAR", link: "/makalalar" },
      { title: "BÄSLEŞIKLER", link: "/basleshikler" },
      { title: "Turkmenistan Ýunesko", link: "/yunesko" },
    ] },
    { title: "Millilik", items: [
      { title: "Kesdeçilik sungay", link: "/kesdecilik-sungaty" },
      { title: "Halyçylyk sungaty", link: "/halycylyk-sungaty" },
      { title: "Keçeçilik sungaty", link: "/kececilik-sungaty" },
     
    ] },{ title: "Gender denligi", items: [
      { title: "Turkmenistanda aýallaryň hukuklary", link: "/gender-denligi/0" },
      { title: "Halkara hukuk resminamalary", link: "gender-denligi/1" },
      { title: "Gender deňliginiň meýilnamasy", link: "gender-denligi/3" },
      { title: "Gender deňliginiň sertifikaty", link: "gender-denligi/4" },
     
    ] },{ title: "Multimediýa", items: [
      { title: "FOTOLAR", link: "gallery" },
      { title: "WIDEOLAR", link: "videos" },
     
    ] },
  ];
  const location = useLocation().pathname.toLowerCase();
  const { language, setLanguage, kabulhanaToggle, setKabulhanaToggle } =
    useContext(LanguageContext);
  const [newest, setNewset] = useState();
  const [languageDrop, setLanguageDrop] = useState(false);
  const [Flag, setFlag] = useState('/'+localStorage.getItem("i18nextLng") + ".png");
  const [toggler, setToggler] = useState(0);
  const taslama = [
    { id: 0, name: "Işewir zenanlar" },
    { id: 1, name: "Alym zenanlar" },
    { id: 2, name: "Zenanlaryň Keşdeçilik sungaty merkezi" },
  ];
  const teklip = [
    { id: 0, name: "Arza" },
    { id: 1, name: "Haýyşnama" },
    { id: 2, name: "Teklip" },
    { id: 3, name: "Ýüzlenme" },
  ];
  const [addFilePosition, setAddFilePosition] = useState([
    1, 2, 3, 4, 5, 6, 7, 8,
  ]);
  const [error, setError] = useState();
  const [junior, setJunior] = useState();
  const [woman, setWoman] = useState(false);
  const [TZB, setTZB] = useState(false);
  const [news, setNews] = useState(false);
  const [millilik, setMillilik] = useState(false);
  const [gender, setGender] = useState(false);
  const [multyMedia, setMultyMedia] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    position: "",
    phone: "",
    purpose_of_appointment: "",
    bolum: "",
    letter_type: "",
    phone: "",
    email: "",
  });

  const handleInputChange = (event) => {
    const { name, value, files } = event.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };
  async function NewestNews() {
    axios
      .get(`${setting.url}/news/news/`, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        setNewset(response.data);
      })
      .catch(function (error) {
        console.error("Error:", error);
      });
    // kabulhana junior
    axios
      .get(`${setting.url}/home/kabulhana-junior/`, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        setJunior(response.data);
      })
      .catch(function (error) {
        console.error("Error:", error);
      });
  }
  useEffect(() => {
    NewestNews();
  }, [language]);
  useEffect(() => {
    setOpenGam(true);
  }, [location]);

  const { t, i18n } = useTranslation();

  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  function handle(e) {
    localStorage.theme = e;
    setTheme(e);
  }
  const [openGam, setOpenGam] = useState(false);

  useEffect(() => {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);
  async function handlePost(event) {
    event.preventDefault();
    const form = new FormData();
    for (const key in formData) {
      if (Object.hasOwnProperty.call(formData, key)) {
        const element = formData[key];
        form.append(key, element);
      }
    }
    axios
      .post(`${setting.url}/kabulhana/kabulhana/`, form)
      .then(function (response) {
        // Handle success
        if (response.status === 201) {
          setKabulhanaToggle(false);
        }
        if (response.status === 400) {
        }
      })
      .catch(function (error) {
        // Handle error
        setError("errorMessage");
        console.error("Error:", error);
      });
  }
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setTZB(false);
        setWoman(false);
        setNews(false);
        setMillilik(false);
        setGender(false);
        setMultyMedia(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  return (
    <div className="lg:h-[200px] h-[100px]">
      <div className="w-full justify-center z-40 bg-white dark:bg-[#202020] pb-4">
        <div className="bg-[#1B6300] dark:bg-[#404040] ">
          <div className="w-11/12 flex sm:justify-between justify-end m-auto py-3">
            <div className="sm:flex gap-6   items-center hidden ">
              <div className="">
                <div className="flex gap-1 items-center text-white  text-xs">
                  <img src="/icons/call.svg" alt="" />
                  448782
                </div>
              </div>
              <div className="min-h-full w-[1px] bg-white  rounded-xl"></div>
              <div className="">
                <div className="flex gap-1 items-center text-white text-xs">
                  <img src="/sms.svg" alt="" />
                  {t("main.04-12-2022, Şenbe")}
                </div>
              </div>
              <div className="min-h-full w-[1px] bg-white  rounded-xl"></div>
            </div>
            <div className="flex justify-between gap-10 items-center">
              <Link to={"/contact-us"} className="text-white ">
                {t("main.habarlashmak")}
              </Link>
              <ul className="text-[#404040] flex gap-3 items-center">
                {theme !== "dark" ? (
                  <button className="w-5" onClick={(e) => handle("dark")}>
                    <img
                      src="/moon-night-mode-space-rocket-svgrepo-com.svg"
                      className="rounded-sm"
                      alt=""
                    />{" "}
                  </button>
                ) : (
                  <button className="w-5" onClick={(e) => handle("light")}>
                    {" "}
                    <img
                      src="/light-mode-svgrepo-com.svg"
                      className="rounded-sm"
                      alt=""
                    />
                  </button>
                )}

                <div>
                  <button
                    className="w-6 h-6"
                    onClick={() => {
                      setLanguageDrop(!languageDrop);
                    }}
                  >
                     <img src={Flag} className="rounded-sm" alt="" />

                  </button>

                  <div
                    className={
                      languageDrop
                        ? "absolute flex flex-col gap-2 mt-2 duration-300"
                        : "overflow-hidden h-0 absolute duration-300"
                    }
                  >
                    <button
                      className="w-6 h-6"
                      onClick={() => {
                        setFlag("/tm.png");
                        i18n.changeLanguage("tm");
                        setLanguage("tm");
                        setLanguageDrop(false);
                      }}
                    >
                      <img src={tmIcon} className="rounded-sm" alt="" />
                    </button>
                    <button
                      className="w-6 h-6"
                      onClick={() => {
                        setFlag("/ru.png");

                        i18n.changeLanguage("ru");
                        setLanguage("ru");
                        setLanguageDrop(false);
                      }}
                    >
                      <img src={ruIcon} className="rounded-sm" alt="" />
                    </button>
                    <button
                      className="w-6 h-6"
                      onClick={() => {
                        setFlag("/en.png");
                        i18n.changeLanguage("en");
                        setLanguage("en");
                        setLanguageDrop(false);
                      }}
                    >
                      <img
                        src={enIcon}
                        className="rounded-sm object-cover"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </ul>
              <button
                onClick={() => {
                  setOpenGam(!openGam);
                  setToggler(toggler + 2);
                }}
                className="flex flex-col gap-[2px] lg:hidden"
              >
                <div className="w-6 h-1 bg-white rounded-md"></div>
                <div className="w-6 h-1 bg-white rounded-md"></div>
                <div className="w-6 h-1 bg-white rounded-md"></div>
              </button>
            </div>
          </div>
        </div>
        <div
          style={
            theme !== "dark"
              ? {
                  // backgroundImage: `url("slider-pattern.png")`,
                  backgroundRepeat: "repeat",
                  backgroundSize: "cover",
                }
              : {
                  backgroundImage: "none",
                }
          }
          className="flex justify-center  bg-hero-pattern dark:bg-[#202020]"
        >
          {" "}
          <div className="sm:grid sm:grid-cols-6 md:grid-cols-8 flex max-w-[1400px]   gap-4 md:gap-0 items-center   m-auto lg:px-28">
            <Link to={"/"} className="col-span-1">
              <img src={logo} alt="" className="w-[100px]  m-auto" />
            </Link>
            <h1 className="lg:text-[26px] col-span-4  font-black text-[#1B6300] text-center dark:text-white py-3">
              {t("main.TÜRKMENISTANYŇ ZENANLAR GURAMASYNYŇ MERKEZI")}
            </h1>
            <img
              src={junior && junior[0]?.header_logo}
              alt=""
              className="w-[90px] sm:block hidden m-auto col-span-1"
            />
            <img
              src={logo3}
              height={"100%"}
              alt=""
              className=" h-full md:block hidden col-span-1"
            />
            <a href="https://tkamm.gov.tm/">
              <img
                src={logo4}
                alt=""
                className="w-[90px] md:block hidden m-auto"
              />
            </a>
          </div>
        </div>
        {/* navigation */}

        <div className="hidden lg:flex flex-row  px-10 lg:px-0 w-full bg-[#D4FFC4] dark:bg-[#202020] dark:text-white text-[#1B6300] gap-5 xl:gap-10 justify-center shadow-md py-2">
          <Link to={`/`} className="hover: min-w-max">
            {t("main.Bash sahypa")}
          </Link>
          {
            listOfLink.map((item,i)=>(

              <DropdownMenuDemo key={i} data={item} />
            ))
          }
          
          

          <div
            onClick={(e) => setKabulhanaToggle(true)}
            className="hover: min-w-max cursor-pointer"
          >
            {t("main.Online Kabulhana")}
          </div>
        </div>

        <div className="flex max-w-[1400px] gap-4 opacity-60 text-[#404040] text-base font-normal w-11/12 lg:w-9/12 m-auto items-center mt-2 border-b-[1px] border-black/30 pb-2 dark:text-white">
          <h1 className="sm:min-w-max sm:block hidden">
            {t("main.Soňky habarlar")}:
          </h1>
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            navigation={{
              prevEl: ".my-prev-button",
              nextEl: ".my-next-button",
            }}
            loop
            spaceBetween={50}
            slidesPerView={1}
          >
            {newest &&
              newest?.results?.map((item, i) => {
                return (
                  <SwiperSlide key={i}>
                    <Link
                      to={`news/${item.id}`}
                      className="flex items-center gap-3 text-sm sm:text-md"
                    >
                      {formatDate(item?.created_at)} |
                      <div className="flex-1 text-[#404040] dark:text-white  font-medium truncate">
                        {item.title}
                      </div>
                    </Link>
                  </SwiperSlide>
                );
              })}
          </Swiper>
          <div className="min-w-max flex items-center gap-3">
            <div className="my-prev-button  border-[1px] rounded-md p-1 cursor-pointer">
              <img alt="" src="/icons/back_arrow.svg" className=" " />
            </div>
            <div className="my-next-button  border-[1px] rounded-md p-1 cursor-pointer">
              <img alt="" src="/icons/back_arrow1.svg" className=" " />
            </div>
          </div>
        </div>
      </div>
      {kabulhanaToggle && (
        <div className=" fixed w-full h-full top-0 left-0 z-40 flex items-center">
          <div
            onClick={(e) => setKabulhanaToggle(false)}
            className="absolute w-full h-full top-0 left-0 bg-black/30 z-10"
          ></div>
          <div className=" bg-white dark:bg-[#404040] lg:w-1/2 m-auto z-50  rounded-md">
            <div className="bg-[#E2E2E2] dark:bg-[#404040] flex w-full px-8 py-2 rounded-t-md  ">
              <h1 className="w-full text-center text-[#404040] dark:text-white text-2xl font-bold font-['DM Sans']">
                {t("main.Online Kabulhana")}
              </h1>
              <div
                className="w-max  text-2xl cursor-pointer"
                onClick={(e) => setKabulhanaToggle(false)}
              >
                ×
              </div>
            </div>
            <form onSubmit={handlePost}>
              <div className="flex flex-col gap-5 px-10 text-[#404040] dark:text-white max-h-[90vh] overflow-y-auto">
                <div className="flex items-center justify-center gap-4">
                  <img
                    src={logo}
                    alt=""
                    className="w-[100px] md:w-[18%] m-auto"
                  />
                  <h1 className="text-center  text-xl font-bold font-['DM Sans']">
                    {t("main.TÜRKMENISTANYŇ ZENANLAR GURAMASYNYŇ MERKEZI")}
                  </h1>
                </div>
                <div className="flex gap-3 justify-between">
                  <h3 className="flex-1 hidden lg:block">{t("main.Adyňyz")}</h3>
                  <input
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder={t("main.Adyňyz")}
                    type="text"
                    className="flex-2 border-[1px] border-gray-300 dark:bg-[#404040] px-2 py-1 rounded-md w-full lg:w-[70%]"
                  />
                </div>
                <div className=" flex gap-3 justify-between">
                  <h3 className="flex-1 hidden lg:block">
                    {t("main.Familýaňyz")}
                  </h3>
                  <input
                    name="surname"
                    value={formData.surname}
                    onChange={handleInputChange}
                    placeholder={t("main.Familýaňyz")}
                    type="text"
                    className="flex-2 border-[1px] border-gray-300 dark:bg-[#404040] px-2 py-1 rounded-md w-full lg:w-[70%]"
                  />
                </div>
                <div className="flex gap-3 justify-between">
                  <h3 className="hidden lg:block">{t("main.Wezipaniz")}</h3>
                  <input
                    name="position"
                    value={formData.position}
                    onChange={handleInputChange}
                    placeholder={t("main.Wezipaniz")}
                    type="text"
                    className="border-[1px] border-gray-300 dark:bg-[#404040] px-2 py-1 rounded-md w-full lg:w-[70%]"
                  />
                </div>
                <div className="flex gap-3 justify-between">
                  <h3 className="hidden lg:block">
                    {t("main.Telefon belgiňiz")}
                  </h3>
                  <input
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    placeholder={t("main.Telefon belgiňiz")}
                    type="text"
                    className="border-[1px] border-gray-300 dark:bg-[#404040] px-2 py-1 rounded-md w-full lg:w-[70%]"
                  />
                </div>
                <div className="flex gap-3 justify-between">
                  <h3 className="hidden lg:block">
                    {t("main.Duşuşygyň maksady")}
                  </h3>
                  <input
                    name="purpose_of_appointment"
                    value={formData.purpose_of_appointment}
                    onChange={handleInputChange}
                    placeholder={t("main.Duşuşygyň maksady")}
                    type="text"
                    className="border-[1px] border-gray-300 dark:bg-[#404040] px-2 py-1 rounded-md w-full lg:w-[70%]"
                  />
                </div>

                <div className="flex gap-3 justify-between">
                  <h3 className="hidden lg:block">{t("main.Email")}</h3>
                  <input
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder={t("main.Email")}
                    type="mail"
                    className="border-[1px] border-gray-300 dark:bg-[#404040] px-2 py-1 rounded-md w-full lg:w-[70%]"
                  />
                </div>

                <div className="flex gap-3 justify-between">
                  <h3 className="hidden flex-1 lg:block">
                    {t("main.File ugrat")}
                  </h3>
                  <div className="w-full lg:w-[70%] gap-3 flex flex-col">
                    {addFilePosition.map((item, i) => (
                      <input
                        key={i}
                        name={"file_" + item}
                        onChange={handleInputChange}
                        placeholder={t("main.File ugrat")}
                        type="file"
                        className="border-[1px] w-full border-gray-300 dark:bg-[#404040] px-2 py-1 rounded-md  "
                      />
                    ))}
                  </div>
                </div>
                <div className="flex gap-3 justify-between">
                  <h3 className="hidden lg:block">{t("main.Merkezler")}</h3>
                  <select
                    name="bolum"
                    className="border-[1px] border-gray-300 dark:bg-[#404040] px-2 py-1 rounded-md w-full lg:w-[70%]"
                    onChange={handleInputChange}
                  >
                    <option selected disabled>
                      {t("main.Merkezler")}
                    </option>
                    {taslama?.map((item, i) => (
                      <option key={i} value={item?.id}>
                        {t("main." + item?.name)}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex gap-3 justify-between">
                  <h3 className="hidden lg:block">{t("main.Teklipler")}</h3>
                  <select
                    name="letter_type"
                    className="border-[1px] border-gray-300 dark:bg-[#404040] px-2 py-1 rounded-md w-full lg:w-[70%]"
                    onChange={handleInputChange}
                  >
                    <option selected disabled>
                      {t("main.Teklipler")}
                    </option>
                    {teklip?.map((item, i) => (
                      <option key={i} value={item?.id}>
                        {t("main." + item?.name)}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="text-red-600 text-center">
                  {error && t("main." + error)}
                </div>
                <div className="flex justify-center gap-5 mb-5">
                  <button
                    onClick={(e) => setKabulhanaToggle(false)}
                    className="bg-white border-green-700 border-2 text-green-700 px-3 py-1 rounded-md"
                  >
                    {t("main.Ýap")}
                  </button>
                  <button
                    type="submit"
                    className="bg-green-700 text-white px-3 py-1 rounded-md"
                  >
                    {t("main.Ugrat")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* mobile navbar */}
      {!openGam && (
        <div
          onClick={() => setOpenGam(!openGam)}
          className="bg-black/30 fixed top-0 left-0 w-full h-full z-40"
        ></div>
      )}

       <div
        className={
          " xl:hidden   h-full pt-16 z-50 fixed top-0 right-0 lg:flex-row flex-col px-10 md:w-1/2 bg-[#EEFFE8] dark:bg-[#181818] dark:text-white text-[#1B6300]" +
          (openGam
            ? toggler > 1
              ? " closeTransition"
              : " hidden"
            : " ktransition")
        }
      >
        <div 
          ref={dropdownRef}
          className="">
          <div
            onClick={(e) => setOpenGam(!openGam)}
            className="absolute cursor-pointer text-xl text-bold top-5 right-5"
          >
            X
          </div>
          <Link to={`/`} className="hover: min-w-max">
            {t("main.Bash sahypa")}
          </Link>
          <div className=" relative">
            <button
              className="group-hover:min-w-max"
              onClick={(e) => setTZB(!TZB)}
            >
              {t("main.TZB-niň Bölümleri")} ▾
            </button>
            <div
              className={
                TZB
                  ? "absolute bg-white flex flex-col w-max items-center duration-300 gap-2 h-max overflow-hidden z-20 text-base font-bold font-['DM Sans'] -ml-8"
                  : "absolute opacity-0 h-0 overflow-hidden  duration-300"
              }
            >
              <Link
                className="hover:bg-[#D4FFC4] min-w-max w-full text-center px-3 text-black/60 "
                to={`/isewir-zenanlar-merkezi/3`}
              >
                {t("main.Aşgabat şäheri  bölümi")}
              </Link>

              <Link
                className="hover:bg-[#D4FFC4] min-w-max w-full text-center px-3 text-black/60 "
                to={`/alym-zenanlar-merkezi/4`}
              >
                {t("main.Arkadag şäheri bölumi")}
              </Link>

              <Link
                className="hover:bg-[#D4FFC4] min-w-max w-full text-center px-3 text-black/60 "
                to={`/zenanlaryn-kesdecilik-sungaty-merkezi/5`}
              >
                {t("main.Ahal welaýat bölümi")}
              </Link>
              <Link
                className="hover:bg-[#D4FFC4] min-w-max w-full text-center px-3 text-black/60 "
                to={`/zenanlaryn-kesdecilik-sungaty-merkezi/5`}
              >
                {t("main.Balkan welaýat bölümi")}
              </Link>
              <Link
                className="hover:bg-[#D4FFC4] min-w-max w-full text-center px-3 text-black/60 "
                to={`/zenanlaryn-kesdecilik-sungaty-merkezi/5`}
              >
                {t("main.Daşoguz welaýat bölümi")}
              </Link>
              <Link
                className="hover:bg-[#D4FFC4] min-w-max w-full text-center px-3 text-black/60 "
                to={`/zenanlaryn-kesdecilik-sungaty-merkezi/5`}
              >
                {t("main.Lebap welaýat bölümi")}
              </Link>

              <Link
                className="hover:bg-[#D4FFC4] min-w-max w-full text-center px-3 text-black/60 "
                to={`/zenanlaryn-kesdecilik-sungaty-merkezi/5`}
              >
                {t("main.Mary welaýat bölümi")}
              </Link>
            </div>
          </div>
          <div className=" relative">
            <button
              className="group-hover:min-w-max"
              onClick={(e) => setWoman(!woman)}
            >
              {t("main.Merkezler")} ▾
            </button>
            <div
              className={
                woman
                  ? "absolute bg-white flex flex-col w-max items-center duration-300 gap-2 h-max overflow-hidden z-20 text-base font-bold font-['DM Sans'] -ml-8"
                  : "absolute opacity-0 h-0 overflow-hidden  duration-300"
              }
            >
              <Link
                className="hover:bg-[#D4FFC4] min-w-max w-full text-center px-3 text-black/60 "
                to={`/isewir-zenanlar-merkezi/3`}
              >
                {t("main.Işewir zenanlar")}
              </Link>

              <Link
                className="hover:bg-[#D4FFC4] min-w-max w-full text-center px-3 text-black/60 "
                to={`/alym-zenanlar-merkezi/4`}
              >
                {t("main.Alym zenanlar")}
              </Link>

              <Link
                className="hover:bg-[#D4FFC4] min-w-max w-full text-center px-3 text-black/60 "
                to={`/zenanlaryn-kesdecilik-sungaty-merkezi/5`}
              >
                {t("main.Zenanlaryň Keşdeçilik sungaty merkezi")}
              </Link>
            </div>
          </div>
          <div className=" relative">
            <button
              className="group-hover:min-w-max"
              onClick={(e) => setNews(!news)}
            >
              {t("main.Täzelik")} ▾
            </button>
            <div
              className={
                news
                  ? "absolute bg-white flex flex-col w-max items-center duration-300 gap-2 h-max overflow-hidden z-20 text-base font-bold font-['DM Sans'] -ml-8"
                  : "absolute opacity-0 h-0 overflow-hidden  duration-300"
              }
            >
              <Link
                className="hover:bg-[#D4FFC4] min-w-max w-full text-center px-3 text-black/60 "
                to={`/careler`}
              >
                {t("main.Çareler")}
              </Link>

              <Link
                className="hover:bg-[#D4FFC4] min-w-max w-full text-center px-3 text-black/60 "
                to={`/News`}
              >
                {t("main.Täzelikler")}
              </Link>

              <Link
                className="hover:bg-[#D4FFC4] min-w-max w-full text-center px-3 text-black/60 "
                to={`/makalalar`}
              >
                {t("main.MAKALALAR")}
              </Link>
              <Link
                className="hover:bg-[#D4FFC4] min-w-max w-full text-center px-3 text-black/60 "
                to={`/basleshikler/`}
              >
                {t("main.BÄSLEŞIKLER")}
              </Link>
              <Link
                className="hover:bg-[#D4FFC4] min-w-max w-full text-center px-3 text-black/60 "
                to={`/yunesko`}
              >
                {t("main.Turkmenistan Ýunesko")}
              </Link>
            </div>
          </div>
          <div className=" relative">
            <button
              className="group-hover:min-w-max"
              onClick={(e) => setMillilik(!millilik)}
            >
              {t("main.Millilik")} ▾
            </button>
            <div
              className={
                millilik
                  ? "absolute bg-white flex flex-col w-max items-center duration-300 gap-2 h-max overflow-hidden z-20 text-base font-bold font-['DM Sans'] -ml-8"
                  : "absolute opacity-0 h-0 overflow-hidden  duration-300"
              }
            >
              <Link
                className="hover:bg-[#D4FFC4] min-w-max w-full text-center px-3 text-black/60 "
                to={`/kesdecilik-sungaty`}
              >
                {t("main.Kesdeçilik sungay")}
              </Link>

              <Link
                className="hover:bg-[#D4FFC4] min-w-max w-full text-center px-3 text-black/60 "
                to={`/halycylyk-sungaty`}
              >
                {t("main.Halyçylyk sungaty")}
              </Link>

              <Link
                className="hover:bg-[#D4FFC4] min-w-max w-full text-center px-3 text-black/60 "
                to={`/kececilik-sungaty`}
              >
                {t("main.Keçeçilik sungaty")}
              </Link>
            </div>
          </div>
          <div className=" relative">
            <button
              className="group-hover:min-w-max"
              onClick={(e) => setGender(!gender)}
            >
              {t("main.Gender denligi")} ▾
            </button>
            <div
              className={
                gender
                  ? "absolute bg-white flex flex-col w-max items-center duration-300 gap-2 h-max overflow-hidden z-20 text-base font-bold font-['DM Sans'] -ml-8"
                  : "absolute opacity-0 h-0 overflow-hidden  duration-300"
              }
            >
              <Link
                className="hover:bg-[#D4FFC4] min-w-max w-full text-center px-3 text-black/60 "
                to={`gender-denligi/0`}
              >
                {t("main.Turkmenistanda aýallaryň hukuklary")}
              </Link>

              <Link
                className="hover:bg-[#D4FFC4] min-w-max w-full text-center px-3 text-black/60 "
                to={`gender-denligi/1`}
              >
                {t("main.Halkara hukuk resminamalary")}
              </Link>
              <Link
                className="hover:bg-[#D4FFC4] min-w-max w-full text-center px-3 text-black/60 "
                to={`gender-denligi/2`}
              >
                {t("main.Gender deňliginiň meýilnamasy")}
              </Link>
              <Link
                className="hover:bg-[#D4FFC4] min-w-max w-full text-center px-3 text-black/60 "
                to={`gender-denligi/3`}
              >
                {t("main.Gender deňliginiň sertifikaty")}
              </Link>
            </div>
          </div>
          <div className=" relative">
            <button
              className="group-hover:min-w-max"
              onClick={(e) => setMultyMedia(!multyMedia)}
            >
              {t("main.Multimediýa")} ▾
            </button>
            <div
              className={
                multyMedia
                  ? "absolute bg-white flex flex-col w-max items-center duration-300 gap-2 h-max overflow-hidden z-20 text-base font-bold font-['DM Sans'] -ml-8"
                  : "absolute opacity-0 h-0 overflow-hidden  duration-300"
              }
            >
              <Link
                className="hover:bg-[#D4FFC4] min-w-max w-full text-center px-3 text-black/60 "
                to={`/gallery`}
              >
                {t("main.FOTOLAR")}
              </Link>

              <Link
                className="hover:bg-[#D4FFC4] min-w-max w-full text-center px-3 text-black/60 "
                to={`/videos`}
              >
                {t("main.WIDEOLAR")}
              </Link>
            </div>
          </div>

          <div
            onClick={(e) => setKabulhanaToggle(true)}
            className="hover: min-w-max cursor-pointer"
          >
            {t("main.Online Kabulhana")}
          </div>
        </div>
      </div> 
    </div>
  );
}

export default Navbar;
