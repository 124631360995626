// axios.js

import axios from "axios";

const instance = axios.create({
  // You can set up a base URL or other configurations here
});

const axiosInstance = async (method, url, config) => {
  try {
    const response = await instance[method](url, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default axiosInstance;
