import React from 'react';
import { PDFObject } from 'react-pdfobject';

const MyPdfViewer = ({ url="https://zenan.gov.tm/media/Insurance_Policy_JOdnmUO.pdf" }) => {
  // Define additional options for PDFObject (optional)
  const options = {
    height: '100vh', // Adjust the height as needed
    width: '100%', // Adjust the width as needed
  };

  return (
    <div className='h-full'>
      <PDFObject  url={url} height='90vh' options={options} >
        <p>
          Error
        </p>
      </PDFObject>
    </div>
  );
};

export default MyPdfViewer;
