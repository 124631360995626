import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PdfDownloadContainer = ({ ListCards }) => {
  const { t } = useTranslation();
  return (  
    <div>
      <div className="">
        <div className="p-5 grid lg:grid-cols-2  gap-4">
          {ListCards?.results?.map((item, i) => {
            return (
              <div
                key={i}
                className="flex flex-col items-center gap-4 border-[1px] rounded-lg pb-2"
              >
                <img
                  alt=""
                  src={item.thumbnail}
                  className="w-full h-[214px] object-cover rounded-lg"
                />
                <div className="flex flex-col justify-between">
                  <div className="text-zinc-500 text-sm font-medium">
                
                  </div>
                </div>
                <Link
                  to={'/pdfreader/?pdfItem='+item.pdf}
                  className="px-3 py-1 bg-green-600 text-white rounded-md "
                >
                  {t("main.iceri_gir")}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PdfDownloadContainer;
