import React, { useContext, useEffect, useRef, useState, useTransition } from "react";
import { Link, useLocation } from "react-router-dom";
import CardWithBgList from "../components/cards/CardWithBgList";
import SwiperCore from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { useParams } from "react-router-dom";
import axios from "axios";
import setting from "../setting.json";
import { LanguageContext } from "../components/utils/lanContext";
import formatDate from "../components/utils/formatDate";
import { useTranslation } from "react-i18next";
import { Pagination } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
// import Swiper and modules styles

const NewsOpen = () => {
  const swiperRef = useRef(null);
  const [images,setImages] = useState([])
  const [currentSlide, setCurrentSlide] = useState(0);
  const handleSwiper = (swiper) => {
    // Now swiper instance is accessible
    if (swiper) {
      swiperRef.current = swiper;
    }
  };

  const changeSlide = (index) => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(index);
      setCurrentSlide(index);
    }
  };
  const { language } = useContext(LanguageContext);
  const location = useLocation().pathname.split("/")[1];
  const { id } = useParams();
  const { t } = useTranslation();
  const [main, setMain] = useState();
  async function MainData() {
    let url = setting.url;
    switch (location.toLowerCase()) {
      case "news":
        url = url + `/news/news/${id}/`;
        break;
      case "ogulabat-eje":
        url = url + `/home/ogulabat/${id}/`;
        break;
      case "bilim":
        url = url + `/bolum/boulm-tazelik/${id}/`;
        break;
      case "basleshikler":
        url = url + `/bolum/boulm-tazelik/${id}/`;
        break;
      case "makalalar":
        url = url + `/bolum/boulm-tazelik/${id}/`;
        break;
      // --------------------
      case "isewir-zenanlar-merkezi":
        url = url + `/bolum/boulm-tazelik/${id}/`;
        break;
      case "alym-zenanlar-merkezi":
        url = url + `/bolum/boulm-tazelik/${id}/`;
        break;
      case "zenanlaryn-kesdecilik-sungaty-merkezi":
        url = url + `/bolum/boulm-tazelik/${id}/`;
        break;
      // -------------------
      case "careler":
        url = url + `/home/chare/${id}/`;
        break;
      case "belli-zenanlar":
        url = url + `/home/science/${id}/`;
        break;
      case "yunesko":
        url = url + `/yunesko/yunesko/${id}/`;
        break;
      case "halkara-gatnashyklar":
        url = url + `/home/okuw/${id}/`; //TODO
        break;
      case "yaslar-syyasaty":
        url = url + `/junior/junior-law/${id}/`;
        break;
      case "kanunlar-we-kararlar":
        url = url + `/junior/law/${id}/`;
        break;
      case "gender-denligi":
        url = url + `/home/gender/${id}/`;
        break;

      // zenanlar
      case "zenanlar":
        url = url + `/home/science/${id}/`;
        break;
      case "welayatlar":
        url = url + `/news/welayat-news/${id}/`;
        break;
      // -------------------
      case "kesdecilik-sungaty":
        url = url + `/home/millilik/${id}/`;
        break;
      case "halycylyk-sungaty":
        url = url + `/home/millilik/${id}/`;
        break;
      case "kececilik-sungaty":
        url = url + `/home/millilik/${id}/`;
        break;
      // additional cases as needed
      default:
        url = url + "/news/news/" + id + "/";
      // code to be executed if none of the cases match the expression
    }

    axios
      .get(url, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        // Handle successful response
        setMain(response.data);
        setImages(response.data.photos)
        
      })
      .catch(function (error) {
        // Handle error
        console.error("Error:", error);
      });
  }
  const [popular, setPopular] = useState();
  
  console.log(images)
  useEffect(()=>{
    if(main?.image){

      setImages([...images,{image:main?.thumbnail}])
    }
  },[main])
  async function PopularNews() {
    axios
      .get(`${setting.url}/bolum/home/`, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        // Handle successful response
        setPopular(response.data);
      })
      .catch(function (error) {
        // Handle error
        console.error("Error:", error);
      });
  }
  useEffect(() => {
    PopularNews();
    MainData();
  }, [language, location, id]);
  console.log(images)
  return (
    <div className=" flex items-center flex-col mb-16 m-auto w-11/12 lg:w-9/12 max-w-[1400px]">
      <div className="flex flex-col lg:flex-row gap-10 ">
        <div
          className={
            !(
              location === "gender-denligi" ||
              location === "isewir-zenanlar-merkezi" ||
              location === "zenanlaryn-kesdecilik-sungaty-merkezi"
            )
              ? "lg:w-[65%] flex flex-col gap-4"
              : "flex flex-col gap-4 w-full"
          }
        >
          <h1 className="text-[#1C2752] text-[25px] md:text-[38px] dark:text-white font-bold font-['DM Sans']">
            {main?.title}
          </h1>
          <div className="flex text-center items-center justify-between">
            <div className="bg-[#D9D9D9] dark:bg-[#323232] dark:text-white rounded-md flex gap-3 items-center px-3 py-2">
              <img src="/icons/eye-fill.svg" alt="" />
              <div>{main?.views}</div>
            </div>
            <div className="text-center text-stone-500 text-base font-normal">
              {formatDate(
                main?.created_at || "2023-11-26T15:46:54.121089+05:00"
              )}
            </div>
            {!(
              location === "gender-denligi" || location === "ogulabat-eje"
            ) && (
              <Link
                to={"/" + location}
                className="bg-[#1B6300] dark:bg-[#B6FF9B] text-white dark:text-black rounded-md px-3 py-2"
              >
                {(location === "isewir-zenanlar-merkezi" &&
                  t("main.Işewir zenanlar")) ||
                  (location === "zenanlaryn-kesdecilik-sungaty-merkezi" &&
                    t("main.Zenanlaryň Keşdeçilik sungaty merkezi")) ||
                  (location === "careler" && t("main.Çareler")) ||
                  (location === "halkara-gatnashyklar" &&
                    t("main.halkara gatnasyklar")) ||
                  (location === "news" && t("main.Täzelikler")) ||
                  (location === "alym-zenanlar-merkezi" &&
                    t("main.Alym zenanlar")) ||
                  location.toUpperCase()}
              </Link>
            )}
          </div>
          {main?.images ?<div className="flex flex-col gap-3 relative w-full">
            <Swiper
              ref={swiperRef}
              modules={[Pagination]}
              spaceBetween={50}
              slidesPerView={1}
              pagination={{ clickable: true }}
              className="min-w-full w-96"
              onSwiper={handleSwiper}
              onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
            >
              {images?.map((image, i) => (
                <SwiperSlide
                  key={i}
                  className="w-full h-full border overflow-hidden pb-10"
                >
                  <img src={image?.image} className="w-full h-full" />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="flex gap-3 w-[316px] overflow-auto scrollbar-hidden">
              {images?.map((image, i) => (
                <img
                  key={i}
                  src={image?.image}
                  onClick={() => changeSlide(i)}
                  className={
                    currentSlide != i
                      ? "w-[60px] cursor-pointer rounded-[4px]"
                      : "w-[60px] border rounded-[4px] border-[#297C3B] cursor-pointer"
                  }
                />
              ))}
            </div>
          </div>:<img src={main?.thumbnail} />}
         {/* -------------------------------- */}
          {/* <div className="text-justify text-lg dark:text-white text-[#000000]   p-1 rounded-md">
            {main?.body}
          </div> */}
          <div dangerouslySetInnerHTML={{ __html: main?.body }} className="text-justify text-lg dark:text-white text-[#000000]   p-1 rounded-md" />
          <div className="mt-4 text-[#1C2752] text-base font-bold font-['DM Sans'] dark:text-white">
            {main?.author}
          </div>
         {/* -------------------------------- */}
         
        </div>
        {!(
          location === "gender-denligi" ||
          location === "isewir-zenanlar-merkezi" ||
          location === "zenanlaryn-kesdecilik-sungaty-merkezi"
        ) && (
          <div className="lg:w-[35%]">
            <CardWithBgList
              ListCards={popular}
              is_absolute_text={true}
              title={"Meňzeş Habarlar"}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default NewsOpen;
