const formatDate = (originalDateString) => {
  const originalDate = new Date(originalDateString);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    timeZone: "UTC", // Adjust the timeZone according to your needs
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(originalDate);

  const [month, day, year, time] = formattedDate.split(/\D/);

  return `${day}/${month}/${year} ${time}`;
};

export default formatDate;