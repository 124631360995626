import React from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DropdownMenuDemo = ({ data }) => {
  const { t} =useTranslation()
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <div>{t('main.'+data.title)}</div>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="min-w-[220px] bg-white rounded-md p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade"
          sideOffset={5}
        >
          {data.items.map((item, i) => (
            <DropdownMenu.Item
              key={i}
              className="group text-[14px] font-bold hover:bg-green-200 leading-none  rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[25px] select-none outline-none "
            >
              <Link className="w-full" to={item.link}>
              {t('main.'+item.title)}
              </Link>
            </DropdownMenu.Item>
          ))}

          <DropdownMenu.Arrow className="fill-white" />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default DropdownMenuDemo;
