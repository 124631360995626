// Home.js
import React, { useContext, useEffect, useState } from "react";
import NewsCardList from "../components/cards/NewsCardList";
import CardWithBgList from "../components/cards/CardWithBgList";
import BeWithUs from "../components/BeWithUs";
import axios from "axios";
import setting from "../setting.json";
import { Link, useLocation } from "react-router-dom";
import Pagination from "../components/Pagination";
import axiosInstance from "../components/utils/axiosInstance";
import { LanguageContext } from "../components/utils/lanContext";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
const News = () => {
  const searchParams = new URLSearchParams(
    window.location.href.substring(window.location.href.indexOf("?") + 1)
  );
  const date = searchParams.get("date");
  const welayat = searchParams.get("w");

  const { language } = useContext(LanguageContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [realTotalPage, setRealTotalPage] = useState();
  const { id } = useParams();
  const location = useLocation().pathname.toLowerCase();
  const [newest, setNewset] = useState();
  const [currentWelayat, setCurrentWelayat] = useState(null);
  async function NewestNews() {
    // news
    let url = setting.url;

    switch (location) {
      case "/news":
        url = url + "/news/news/";
        break;
      case "/bilim":
        url = url + "/bolum/boulm-tazelik/?bolum=0";
        break;
      case "/sport":
        url = url + "/bolum/boulm-tazelik/?bolum=1";
        break;
      case "/makalalar":
        url = url + "/bolum/boulm-tazelik/?bolum=2";
        break;
      case "/medeni-miras":
        url = url + "/bolum/boulm-tazelik/?bolum=3";
        break;
      case "/basleshikler":
        url = url + "/bolum/boulm-tazelik/?bolum=1";
        break;
      case "/isjen-agzalar":
        url = url + "/bolum/boulm-tazelik/?bolum=5";
        break;
      case "/careler":
        url = url + "/home/chare/";
        break;
      case "/yunesko":
        url = url + "/yunesko/yunesko/";
        break;
      case "/okuwlar":
        url = url + "/home/okuw/";
        break;
      case "/yaslar-syyasaty":
        url = url + "/junior/junior-law/";
        break;
      case "/kanunlar-we-kararlar":
        url = url + "/junior/law/";
        break;
      // zenanlar
      case "/belli-zenanlar":
        url = url + "/home/science/";
        break;
      case "/alym-zenanlar":
        url = url + "/bolum/boulm-tazelik/?bolum=3";
        break;
      case "/kesdeci-zenanlar":
        url = url + "/bolum/boulm-tazelik/?bolum=4";
        break;
      case "/telekeci-zenanlar":
        url = url + "/home/chare/";
        break;
      case "/turgen-zenanlar":
        url = url + "/home/science/";
        break;
      case "/eneler":
        url = url + "/yunesko/yunesko/";
        break;
      case "/halkara-gatnashyklar":
        url = url + "/home/okuw/";
        break;
      case "/welayatlar":
        url = url + "/news/welayat-news/";
        break;
      case "/zenanlar/" + id:
        url = url + "/home/science/?category=" + id;
        break;
      // --------------------
      case "/halycylyk-sungaty":
        url = url + "/home/millilik/?type=" + 1;
        break;
      case "/kesdecilik-sungaty":
        url = url + "/home/millilik/?type=" + 0;
        break;
      case "/kececilik-sungaty":
        url = url + "/home/millilik/?type=" + 2;
        break;
      // default:
      //   url = url + "/news/news";
      // code to be executed if none of the cases match the expression
    }
    if (url.includes("?")) {
      if (currentPage !== 1) {
        url = url + "&page_size=" + currentPage;
      }
      if (date && currentPage !== 1) {
        url = url + "&created_at=" + date;
      }
      if (date && currentPage === 1) {
        url = url + "&created_at=" + date;
      }
      if (welayat !== null && currentPage === 1) {
        url = url + "&welayat=" + welayat;
      }
      if (welayat !== null && currentPage !== 1) {
        url = url + "&welayat=" + welayat;
      }
    } else {
      if (currentPage !== 1) {
        url = url + "?page_size=" + currentPage;
      }
      if (date && currentPage !== 1) {
        url = url + "&created_at=" + date;
      }
      if (date && currentPage === 1) {
        url = url + "?created_at=" + date;
      }
      if (welayat !== null && currentPage === 1) {
        url = url + "?welayat=" + welayat;
      }
      if (welayat !== null && currentPage !== 1) {
        url = url + "&welayat=" + welayat;
      }
    }

    // juniar law

    axios
      .get(url, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        // Handle successful response
        console.log(response.data);
        setNewset(response.data);
        if (response?.data.count / 4 > parseInt(response?.data.count / 4)) {
          setRealTotalPage(parseInt(response?.data.count / 4) + 1);
        } else {
          setRealTotalPage(parseInt(response?.data.count / 4));
        }
      })
      .catch(function (error) {
        // Handle error
        console.error("Error:", error);
      });
  }
  const [popular, setPopular] = useState();

  async function PopularNews() {
    axios
      .get(`${setting.url}/bolum/home/`, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        // Handle successful response
        setPopular(response.data);
      })
      .catch(function (error) {
        // Handle error
        console.error("Error:", error);
      });
  }

  useEffect(() => {
    setCurrentPage(1);
  }, [location]);
  useEffect(() => {
    

      NewestNews();
    
    PopularNews();
  }, [location, currentPage, language, date, currentWelayat]);
  const { t } = useTranslation();
  console.log(newest)
  return (
    <div className="">
      <div className="flex  lg:flex-row flex-col gap-3 m-auto w-11/12 lg:w-9/12 max-w-[1400px]">
        <div className="xl:w-[65%] w-full">
          <NewsCardList location={location} ListCards={newest} id={id} />
          <Pagination
            ListCards={newest}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            realTotalPage={realTotalPage}
            setRealTotalPage={setRealTotalPage}
            part={4}
          />
        </div>
        <div className="xl:w-[35%]  w-11/12 lg:w-9/12 max-w-[1400px]">
          {location !== "/welayatlar" && (
            <CardWithBgList ListCards={popular} title={"Meňzeş Habarlar"} />
          )}
          {location === "/welayatlar" && (
            <div className="xl:w-[35%] w-full gap-3">
              <h1 className="text-lg">{t("main.welayatlar")}:</h1>
              <ul className="flex flex-col">
                <Link
                  to="?w=0"
                  className="cursor-pointer duration-300 px-2 py-1 border-b border-gray-300 hover:border-yellow-500"
                >
                  {t("main.ashgabat")}
                </Link>
                <Link
                  to="?w=1"
                  className="cursor-pointer duration-300 px-2 py-1 border-b border-gray-300 hover:border-yellow-500"
                >
                  {t("main.arkadag")}
                </Link>
                <Link
                  to="?w=2"
                  className="cursor-pointer duration-300 px-2 py-1 border-b border-gray-300 hover:border-yellow-500"
                >
                  {t("main.ahal")}
                </Link>
                <Link
                  to="?w=3"
                  className="cursor-pointer duration-300 px-2 py-1 border-b border-gray-300 hover:border-yellow-500"
                >
                  {t("main.balkan")}
                </Link>
                <Link
                  to="?w=4"
                  className="cursor-pointer duration-300 px-2 py-1 border-b border-gray-300 hover:border-yellow-500"
                >
                  {t("main.dashaguz")}
                </Link>
                <Link
                  to="?w=5"
                  className="cursor-pointer duration-300 px-2 py-1 border-b border-gray-300 hover:border-yellow-500"
                >
                  {t("main.lebap")}
                </Link>
                <Link
                  to="?w=6"
                  className="cursor-pointer duration-300 px-2 py-1 border-b border-gray-300 hover:border-yellow-500"
                >
                  {t("main.mary")}
                </Link>
              </ul>
            </div>
          )}
        </div>
      </div>
      <BeWithUs />
    </div>
  );
};

export default News;
