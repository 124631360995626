import React from "react";
import { Link } from "react-router-dom";

const GalleryCard = ({ ListCards }) => {
  return (
    <div className="  dark:bg-[#181818] p-5 px-0 pt-0 mt-5">
      <div className="m-auto w-11/12 lg:w-9/12 max-w-[1400px] my-10">
        <div className="p-5 grid lg:grid-cols-3 md:grid-cols-2 gap-4  flex-col">
          {ListCards?.results?.map((item, i) => (
            <Link
              to={`/gallery/` + item.id}
              key={i}
              className="flex  gap-4 relative"
            >
              <img
                alt=""
                src={item.source}
                className="h-[350px] object-cover  rounded-lg"
              />
              <div className="absolute bottom-0 ">
                <h1 className="text-white text-[32px] font-bold p-5">
                  {item.name}
                </h1>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
export default GalleryCard;
