import React, { useEffect } from 'react'
import MyPdfViewer from '../components/pdf/PdfReaer'
import setting from '../setting.json'
import axios from 'axios'
import { useLocation } from 'react-router-dom'

const Pdf = () => {
  const params = useLocation().search.split('=')[1];
  return (
    <div className='h-[100vh]'>
        <MyPdfViewer url={params}  />
    </div>
  )
}

export default Pdf