import React, { useState } from "react";
import axios from "axios";
import setting from "../setting.json";
import { useTranslation } from "react-i18next";

const BeWithUs = () => {
  const { t, i18n } = useTranslation();

  const [mail, setMail] = useState("");
  async function handlePost() {
    const postData = {
      email: mail,
    };

    // Make a POST request using Axios
    axios
      .post(`${setting.url}/kabulhana/email/`, postData)
      .then(function (response) {
        // Handle success
      })
      .catch(function (error) {
        // Handle error
      });
  }
  return (
    <div className="bg-[#EEFFE8] dark:bg-[#181818]">
      <div className=" flex lg:flex-row flex-col max-w-[1400px]  p-5 items-center gap-5 m-auto w-9/12 my-10 justify-between">
        <h1 className="text-center text-black dark:text-white text-[18px] lg:text-[30px] font-bold">
          {t("main.BIZE GOŞULYŇ WE BIZIŇ TÄZELIKLERIMIZDEN HABARLY BOLUŇ!")}
        </h1>
        <div className="flex lg:min-w-max min-w-full">
          <input
            value={mail}
            onChange={(e) => setMail(e.target.value)}
            className="px-3 py-2 border-[1px] w-full rounded-s-md dark:bg-[#0C0C0C] "
            type="mail"
            placeholder="Email"
          />
          <button
            onClick={(e) => handlePost()}
            className="bg-[#1B6300] dark:bg-[#AAAAAA] dark:text-black dark:border-[#AAAAAA] text-white px-3 py-2 rounded-e-md border-[1px] border-[#1B6300]"
          >
            {t("main.Ugrat")}
          </button>
        </div>
      </div>
    </div>
  );
};
export default BeWithUs;
