// Home.js
import React, { useState } from "react";
import axios from "axios";
import setting from "../setting.json";
import { useTranslation } from "react-i18next";
import BeWithUs from "../components/BeWithUs";

const AboutUs = () => {
  const [name, setName] = useState();
  const [mail, setMail] = useState();
  const [message, setMessage] = useState();
  const [error, setError] = useState();
  async function handlePost() {
    const postData = {
      full_name: name,
      email: mail,
      message: message,
    };
    axios
      .post(`${setting.url}/kabulhana/get-in-touch/`, postData)
      .then(function (response) {
        // Handle success
      })
      .catch(function (error) {
        // Handle error
        setError("hhhh");
      });
  }
  const { t } = useTranslation();
  return (
    <div>
      <div className="sm:mt-16 m-auto w-11/12 lg:w-9/12 max-w-[1400px]">
        <div className="flex gap-10 xl:gap-44 flex-col-reverse mb-5 lg:flex-row">
          <div className="flex flex-2 flex-col gap-4 w-max dark:text-white">
            <h1 className="min-w-max flex gap-2 text-black dark:text-white text-[28px] font-bold font-['DM Sans'] tracking-wide">
              {t("main.Habarlasmak ucin")}
            </h1>
            <h2 className="min-w-max flex gap-2 ">
              <img src="/icons/location.svg" alt="" />
              744000, {t(['main.Aşgabat şäheri, Garaşsyzlyk şaýoly,104'])}
            </h2>
            <a href="tel:448782" className="min-w-max flex gap-2">
              <img src="/icons/round-phone-24px.svg" alt="" />
              {t(['main.Kabulhana'])} 448782
            </a>
            <a href="tel:448842" className="min-w-max flex gap-2">
              <img src="/icons/round-phone-24px.svg" alt="" />
              {t(['main.Guramaçylyk bölümi'])} 448842
            </a>
            <a href="tel:448756" className="min-w-max flex gap-2">
              <img src="/icons/round-local-printshop-24px.svg" alt="" />
              {t(['main.Faks'])} 448756
            </a>
          </div>
          <div className="flex-1">
            <img
              src="/banner1.jpg"
              className=" object-cover rounded-md w-full"
              alt=""
            />
          </div>
        </div>
        <div className="dark:text-white">
          <div>
            <h2 className="text-black dark:text-white text-[28px] font-bold font-['DM Sans'] tracking-wide">
              {t("main.Bize hat yollan!")}
            </h2>
            <div className="grid sm:grid-cols-2 gap-10">
              <div className="flex flex-col gap-10">
                <div>
                  <h3 className="pb-2">{t("main.Doly adynyz")}</h3>
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    className="border-[1px] px-2 py-1 rounded-md  w-full h-full"
                    placeholder="Kerwen Garahanow"
                  />
                </div>
                <div>
                  <h3 className="pb-2">{t("main.Email")}</h3>
                  <input
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                    type="mail"
                    className="border-[1px] px-2 py-1 rounded-md  w-full h-full"
                    placeholder="example@gmail.com"
                  />
                </div>
              </div>
              <div>
                <h3>{t("main.Message")}</h3>
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Salam. Men ...."
                  className="h-full border-[1px] text-start px-2 py-1 rounded-md mt-2 w-full"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 text-red-500 mb-4">
          {error && t("main.errorMessage")}
        </div>
        <button
          className=" border-green-600 text-green-700 border-2 rounded-md px-2 py-1 m-auto"
          onClick={(e) => handlePost()}
        >
          {t("main.Ugrat")}
        </button>
      </div>
      <BeWithUs />
    </div>
  );
};

export default AboutUs;
