// Home.js
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import goller from "./goller.png";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/pagination";
import ListCardCategory from "../components/cards/ListCardCategory";
import FixedTextCardsList from "../components/cards/FixedTextCardsList";
import UnderTextCards from "../components/cards/UnderTextCards";
import ViewEyesCardList from "../components/cards/ViewEyesCardList";
import JustImageCardLst from "../components/cards/JustImageCardLst";
import BeWithUs from "../components/BeWithUs";
import axios from "axios";
import setting from "../setting.json";
import { LanguageContext } from "../components/utils/lanContext";
import { Link } from "react-router-dom";
import ArkadagKitap from "../components/cards/arkadagKitap";
const UnderTextCardsData = [
  {
    title: "“Zenan kalby” zurnaly",
    body: "­«Ar­ka­dag­ly ­Ýaş­lar» ­at­ly ­aý­da­ bir ­ge­zek­ ne­şir­ edil­ýän ­elektron­ žur­na­lyň il­kin­ji ­sa­ny­ oky­jy­la­ra ­go­wuş­dy.­",
    img: "/image 2.png",
  },
  {
    title: "ONLAÝN KABULHANA",
    body: "Türkmenistanyň Magtymguly adyndaky Ýaşlar guramasynyň onlaýn kabulhana mümkinçiligi ýola goýuldy",
    img: "/image 2.png",
  },
];
const Home = () => {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  useEffect(() => {
    setTheme();
  }, [localStorage.getItem("theme")]);
  const { language } = useContext(LanguageContext);
  const [arkadag, setArkadag] = useState();
  const [ogulabat, setOgulabat] = useState();
  const [bolumFirst, setBolumFirst] = useState();
  const [bolumSecond, setBolumSecond] = useState();
  const [bolumThird, setBolumThird] = useState();
  const [newset, setNewset] = useState();
  async function bolum1() {
    axios
      .get(setting.url + "/junior/junior-pdf/", {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        // Handle successful response
        setArkadag(response?.data);
      })
      .catch(function (error) {
        // Handle error
        console.error("Error:", error);
      });
    axios
      .get(`${setting.url}/news/news/`, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        setNewset(response.data);
      })
      .catch(function (error) {
        console.error("Error:", error);
      });
    let url = `${setting.url}/bolum/boulm-tazelik/?bolum=1`;
    axios
      .get(url, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        // Handle successful response
        setBolumFirst(response.data);
      })
      .catch(function (error) {
        // Handle error
        console.error("Error:", error);
      });
  }
  async function bolum2() {
    let url = `${setting.url}/home/chare/`;

    axios
      .get(url, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        // Handle successful response
        setBolumSecond(response.data);
      })
      .catch(function (error) {
        // Handle error
        console.error("Error:", error);
      });
  }
  async function bolum3() {
    let url = `${setting.url}/bolum/boulm-tazelik/?bolum=2`;

    axios
      .get(url, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        // Handle successful response
        setBolumThird(response.data);
      })
      .catch(function (error) {
        // Handle error
        console.error("Error:", error);
      });
  }
  const [albums, setAlbums] = useState();
  async function getAlbums() {
    axios
      .get(`${setting.url}/multimedia/thumbnail/`, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        // Handle successful response
        setAlbums(response.data);
      })
      .catch(function (error) {
        // Handle error
        console.error("Error:", error);
      });
  }
  const [edaras, setEdaras] = useState();
  const [pdf, setPdf] = useState();
  const [schools, setSchools] = useState();
  const [yas_alym, setYas_alym] = useState();
  const [banner, setBanner] = useState();
  const [junior, setJunior] = useState([]);
  const [holiday, setHoliday] = useState();

  async function getEdaras() {
    axios
      .get(`${setting.url}/home/edara/`, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        // Handle successful response
        setEdaras(response.data);
      })
      .catch(function (error) {
        // Handle error
        console.error("Error:", error);
      });
    axios
      .get(`${setting.url}/home/holiday/`, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        // Handle successful responsecons
        setHoliday(response.data);
        console.log(holiday);
      })
      .catch(function (error) {
        // Handle error
        console.error("Error:", error);
      });
    axios
      .get(`${setting.url}/home/pdf/1`, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        // Handle successful response
        setPdf(response.data);
      })
      .catch(function (error) {
        // Handle error
        console.error("Error:", error);
      });
    axios
      .get(`${setting.url}/home/okuw/`, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        // Handle successful response
        setSchools(response.data);
      })
      .catch(function (error) {
        // Handle error
        console.error("Error:", error);
      });
    // ogulabat
    axios
      .get(`${setting.url}/home/ogulabat/1`, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        // Handle successful response
        setOgulabat(response.data);
      })
      .catch(function (error) {
        // Handle error
        console.error("Error:", error);
      });
    axios
      .get(`${setting.url}/junior/law/`, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        // Handle successful response
        setYas_alym(response.data);
      })
      .catch(function (error) {
        // Handle error
        console.error("Error:", error);
      });
    axios
      .get(`${setting.url}/home/banner/`, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        // Handle successful response
        setBanner(response.data);
      })
      .catch(function (error) {
        // Handle error
        console.error("Error:", error);
      });
    axios
      .get(`${setting.url}/home/kabulhana-junior/`, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        // Handle successful response
        setJunior(response.data);
        console.log(junior, "--------");
      })
      .catch(function (error) {
        // Handle error
        console.error("Error:", error);
      });
  }
  useEffect(() => {
    bolum1();
    bolum2();
    bolum3();
    getAlbums();
    getEdaras();
  }, [language]);
  const { t } = useTranslation();
  return (
    <>
      <div className="relative pb-8 bg-slider-pattern">
        <div className="flex flex-col sm:flex-row gap-4 w-11/12 lg:w-9/12 max-w-[1400px] m-auto">
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            pagination={{ el: ".swiper-pagination", clickable: true }}
            loop
            spaceBetween={50}
            slidesPerView={1}
            className="mb-5 w-full"
          >
            {newset?.results?.map((item, i) => (
              <SwiperSlide key={i} className="  object-cover ">
                <Link to={`/news/` + item.id} className="relative">
                  <img
                    alt=""
                    src={item.thumbnail}
                    className="w-full h-44 sm:h-96 rounded-lg object-contain bg-white"
                  />

                  <h1 className="max-w-full truncate text-md font-bold text-white bg-[#1B6300] p-2 rounded-md text-center absolute top-2 left-2 shadow-lg ">
                    {item.title}
                  </h1>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="h-96 gap-8 flex flex-col md:w-[60%]">
            <Link to={"/gender-denligi/1"} className=" relative">
              <img
                className="w-full object-cover rounded-md h-44 "
                src={junior.length > 0 && junior[0]?.gender_image}
                alt=""
              />
              <h1 className="text-md font-bold text-white bg-[#1B6300] p-2 rounded-md text-center absolute top-2 left-2 shadow-lg ">
                {t("main.Gender denligi")}
              </h1>
            </Link>

            <Link to={"/welayatlar"} className=" relative">
              <img
                className="w-full object-cover rounded-md h-44 "
                src={junior.length > 0 && junior[0]?.welayatlar_image}
                alt=""
              />
              <h1 className="text-md font-bold text-white bg-[#1B6300] p-2 rounded-md text-center absolute top-2 left-2 shadow-lg ">
                {t("main.welayatlar")}
              </h1>
            </Link>
          </div>
        </div>

        <div className="swiper-pagination bottom-0"></div>
      </div>
      <div className="w-full bg-[#D4FFC4] h-16 ">
        <img
          src={goller}
          className="max-w-[1400px] m-auto w-11/12 lg:w-9/12 h-full object-cover"
        />
      </div>
      <div className="mt-10 grid xl:grid-cols-3 grid-cols-1  m-auto w-11/12 lg:w-9/12 max-w-[1400px]   gap-10">
        <ListCardCategory
          link={"/basleshikler/"}
          title={"BÄSLEŞIKLER"}
          linkA={"/basleshikler"}
          ListCards={bolumFirst}
        />
        <ListCardCategory
          link={"/careler/"}
          linkA={"/careler"}
          title={"Çareler"}
          ListCards={bolumSecond}
        />
        <ListCardCategory
          link={"/makalalar/"}
          linkA={"/makalalar"}
          title={"MAKALALAR"}
          ListCards={bolumThird}
        />
      </div>

      <div
        style={{
          // backgroundImage: `url("bg1 3.png")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="my-10 dark:bg-none bg-arkadag-kitaplar"
      >
        <ArkadagKitap
          ListCards={arkadag}
          title={"Gahryman Arkadagymyzyň Kitaplary"}
        />
      </div>
      <div className=" flex m-auto w-11/12 lg:w-9/12 max-w-[1400px]">
        <UnderTextCards
          ogulabat_eje={ogulabat}
          junior={junior}
          ListCards={UnderTextCardsData}
        />
      </div>

      <div className="rounded-xl border m-auto flex flex-col md:flex-row max-w-[1400px] w-11/12 lg:w-9/12 shadow-sm p-4">
        <img src="ena-tagzym.png" />
        <div className="flex flex-col items-center justify-center">
          <h2 className=" stroke-[#1B6300] text-center text-[#1B6300] text-[41px] space-x-1 font-[700]">
            {t('main.Ena tagzym mukaddeslige tagzym')}
          </h2>

          <p className="text-center w-10/12">
            ­
            {t(
              "main.«Ar­ka­dag­ly ­Ýaş­lar» ­at­ly ­aý­da­ bir ­ge­zek­ ne­şir­ edil­ýän ­elektron­ žur­na­lyň il­kin­ji ­sa­ny­ oky­jy­la­ra ­go­wuş­dy."
            )}
            ­
          </p>
          <a
            href={pdf?.pdf}
            className="border-[1px] px-2 py-1 rounded-md text-[#1B6300] border-[#1B6300] w-max mx-auto mt-5  "
          >
            {t("main.Ginisleyin")}
          </a>
        </div>
      </div>
      <BeWithUs />
      <div
        className="my-10 dark:bg-none bg-footer-texture"
        style={{
          // backgroundImage: `url("bg1 2.png")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPositionY: "bottom",
        }}
      >
        <ViewEyesCardList
          title={"EDARALAR"}
          // linkA={"/edaralar"}
          ListCards={edaras}
        />
      </div>
    </>
  );
};

export default Home;
