import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../utils/lanContext";
import { useTranslation } from "react-i18next";

const UnderTextCards = ({ ListCards, junior, ogulabat_eje }) => {
  const { setKabulhanaToggle } = useContext(LanguageContext);
  const { t } = useTranslation();

  return (
    <div className="min-w-[400px]  min-h-[300px] ">
      <div className="p-5 flex flex-col md:grid md:grid-cols-3  gap-2 ">
        <div className="gap-2 h-full flex flex-col justify-between col-span-2 relative">
          <div className="flex flex-col items-center justify-between h-full">
            <img
              alt=""
              src={ogulabat_eje ? ogulabat_eje?.thumbnail : ""}
              className=" object-cover rounded-lg h-full"
            />
          </div>
          <div className="absolute bottom-0 bg-[#FFF]/50 p-4 flex justify-center flex-col w-full">
            <h1 className="text-black dark:text-white text-2xl lg:text-4xl font-bold text-center">
              {ogulabat_eje?.title}
            </h1>
            <p dangerouslySetInnerHTML={{ __html: ogulabat_eje?.body }} className="text-center"></p>
            <Link
              to={"/ogulabat-eje/1"}
              className="border-[1px] px-2 py-1 rounded-md text-[#1B6300] border-[#1B6300] w-max mx-auto mt-5  "
            >
              {t("main.Ginisleyin")}
            </Link>
          </div>
        </div>

        <div className="flex flex-col gap-3 col-span-1">
          <div className="gap-2 h-full flex flex-col justify-between relative ">
            <div className="flex flex-col items-center justify-between h-max">
              <img
                alt=""
                src={junior ? junior[0]?.junior_image : ""}
                className=" object-cover rounded-lg h-full w-full"
              />
            </div>
            <div className="absolute bottom-0 bg-[#FFF]/50 p-4 flex justify-center flex-col w-full">
              <h1 className="text-black dark:text-white text-2xl lg:text-4xl font-bold text-center">
                {t("main." + ListCards && ListCards[0]?.title)}
              </h1>

              <a
                href={"https://metbugat.gov.tm/tk/magazine/4"}
                className="border-[1px] px-2 py-1 rounded-md text-[#1B6300] border-[#1B6300] w-max mx-auto mt-5  "
              >
                {t("main.Ginisleyin")}
              </a>
            </div>
          </div>

          <div className=" flex flex-col items-center justify-between relative ">
            <img
              alt=""
              src={junior ? junior[0]?.kabulhana_image : ""}
              className=" object-cover rounded-lg  h-full w-full  "
            />

            <div className="absolute bottom-0 bg-[#FFF]/50 p-4 flex justify-center flex-col w-full">
              <h1 className="text-black dark:text-white text-2xl lg:text-4xl font-bold text-center">
                {t("main." + ListCards && ListCards[1]?.title)}
              </h1>

              <div
                onClick={() => setKabulhanaToggle(true)}
                className="cursor-pointer border-[1px] px-2 py-1 rounded-md text-[#1B6300] border-[#1B6300] w-max m-auto mt-5 "
              >
                {t("main.Ginisleyin")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UnderTextCards;
