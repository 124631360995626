import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import setting from "../setting.json";
import { LanguageContext } from "../components/utils/lanContext";
import Pagination from "../components/Pagination";
import GalleryCard from "../components/cards/GalleryCard";

const Gallery = () => {
  const { language } = useContext(LanguageContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [realTotalPage, setRealTotalPage] = useState();
  const [albums, setAlbums] = useState();
  async function getAlbums() {
    axios
      .get(`${setting.url}/multimedia/thumbnail/`, {
        headers: {
          "Accept-Language": language,
        },
      })
      .then(function (response) {
        // Handle successful response
        setAlbums(response.data);
        if (response?.data.count / 9 > parseInt(response?.data.count / 9)) {
          setRealTotalPage(parseInt(response?.data.count / 9) + 1);
        } else {
          setRealTotalPage(parseInt(response?.data.count / 9));
        }
      })
      .catch(function (error) {
        // Handle error
        console.error("Error:", error);
      });
  }
  useEffect(() => {
    getAlbums();
  }, [language]);
  return (
    <div>
      <GalleryCard ListCards={albums} />
      <Pagination
        ListCards={albums}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        realTotalPage={realTotalPage}
        setRealTotalPage={setRealTotalPage}
        part={9}
      />
    </div>
  );
};
export default Gallery;
